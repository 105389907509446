import { useCallback } from "react";
import _ from "lodash";

import { useSettingSelector } from "./useSelector";
import { parseMultipleFilterSettingToUri } from "utils/helper";

const useURLDefaultParams = () => {
  const setting = useSettingSelector();

  const addURLDefaultParams = useCallback(
    (url) => {
      const parseUriParams = (filters) => {
        return filters
          .map(([paramName, filterKey]) =>
            parseMultipleFilterSettingToUri(paramName, filterKey, setting)
          )
          .filter((row) => !!row)
          .join("&");
      };

      const formatTaskParams = (input, type) => {
        const statusMapping = {
          in_queue: 1,
          ongoing: 2,
          done: 3,
        };
        const result = Object.entries(input).map(([key, value]) => {
          // Extract the status part from the key
          const statusKey =
            type === "sort_by"
              ? key.match(/task_display_(.*)_sort_by/)[1]
              : key.match(/task_display_(.*)_order_by/)[1];
          return {
            status_id: statusMapping[statusKey],
            [type]: value,
          };
        });
        return result.filter((row) => !!row?.[type]);
      };

      let searchParams = "";

      switch (url) {
        case "/presence/attendance/daily":
          searchParams = parseUriParams([
            ["org_structures", "filter_daily_attendance_hr_org_structure_ids"],
          ]);
          return `/presence/attendance/daily?${searchParams}`;
        case "/presence/attendance/summary":
          searchParams = parseUriParams([
            [
              "org_structures",
              "filter_summary_attendance_hr_org_structure_ids",
            ],
          ]);
          return `/presence/attendance/summary?${searchParams}`;
        case "/task":
          searchParams = parseUriParams([
            ["time_ended", "task_display_filter_time_ended"],
            ["org_structure_id", "task_display_filter_hr_org_structure_id"],
            ["job_position_id", "task_display_filter_hr_job_position_id"],
            ["task_status_id", "task_display_filter_hr_task_status_id"],
            ["task_priority_id", "task_display_filter_hr_task_priority_id"],
            [
              "task_approval_status_id",
              "task_display_filter_hr_task_approval_status_id",
            ],
            ["is_archive", "task_display_filter_is_archive"],
            ["layout", "task_display_layout"],
          ]);

          const keySortBy = {
            task_display_in_queue_sort_by:
              setting?.task_display_in_queue_sort_by,
            task_display_ongoing_sort_by: setting?.task_display_ongoing_sort_by,
            task_display_done_sort_by: setting?.task_display_done_sort_by,
          };
          const keyOrderBy = {
            task_display_in_queue_order_by:
              setting?.task_display_in_queue_order_by,
            task_display_ongoing_order_by:
              setting?.task_display_ongoing_order_by,
            task_display_done_order_by: setting?.task_display_done_order_by,
          };

          const sortBy = formatTaskParams(keySortBy, "sort_by");
          const orderBy = formatTaskParams(keyOrderBy, "order_by");

          const sortByToParams = !_.isEmpty(sortBy)
            ? `${searchParams ? `&` : ""}${`sort_by=${encodeURIComponent(
                JSON.stringify(sortBy)
              )}`}`
            : "";
          const orderByToParams = !_.isEmpty(orderBy)
            ? `${
                searchParams || sortByToParams ? `&` : ""
              }${`order_by=${encodeURIComponent(JSON.stringify(orderBy))}`}`
            : "";
          return `/task?${searchParams}${sortByToParams}${orderByToParams}`;
        default:
          return url;
      }
    },
    [setting]
  );

  return { addURLDefaultParams };
};

export default useURLDefaultParams;
