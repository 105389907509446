import { useCallback, useMemo } from "react";
import {
  Table as TableAntd,
  Col,
  Card,
  Row,
  ConfigProvider,
  Skeleton,
  Empty,
  Typography,
} from "antd";
import _ from "lodash";

import { useConfigSelector } from "hooks/useSelector";

export const ResponsiveTableSummary = ({
  title,
  titleColSpan,
  datas = [],
  className,
}) => {
  const { isMobileView, isTabView } = useConfigSelector();

  const getContent = useCallback(() => {
    const content = [];

    if (isMobileView || isTabView) {
      content.push(
        <TableAntd.Summary.Cell key="title" colSpan={2}>
          <Card
            title={<div className="text-lg font-bold py-1 px-0">{title}</div>}
            size="small"
            styles={{
              header: { background: "#f1f4f8" },
              body: { background: "#f1f4f8" },
            }}
          >
            <Row>
              {datas.map((data, i) => {
                if (data.hide) return <div key={data.title ? data.title : i} />;
                return (
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    key={data.title ? data.title : i}
                    className="mt-3 px-1"
                  >
                    {data.title}
                    <Typography.Title level={5}>{data.value}</Typography.Title>
                  </Col>
                );
              })}
            </Row>
          </Card>
        </TableAntd.Summary.Cell>
      );
    } else {
      content.push(
        <TableAntd.Summary.Cell colSpan={titleColSpan} key="title">
          <Typography.Text strong>{title}</Typography.Text>
        </TableAntd.Summary.Cell>
      );
      datas.forEach((data, i) => {
        if (data.hide) return;
        content.push(
          <TableAntd.Summary.Cell
            align={data.align ? data.align : "right"}
            colSpan={data.colSpan}
            key={data.title ? data.title : i}
            className={data?.className}
          >
            {typeof data.render === "function" ? (
              data.render()
            ) : (
              <Typography.Text strong>{data.value}</Typography.Text>
            )}
          </TableAntd.Summary.Cell>
        );
      });
    }
    return content;
  }, [datas, isMobileView, isTabView, title, titleColSpan]);

  return (
    <TableAntd.Summary.Row
      style={{ background: "#f1f4f8" }}
      className={className}
    >
      {getContent()}
    </TableAntd.Summary.Row>
  );
};

const Table = ({
  rowSelection,
  columns = [],
  loading,
  withTitle,
  withNumbering,
  onRow,
  configProps,
  isFloatingHeader,
  data,
  ...props
}) => {
  const { isMobileView, isTabView } = useConfigSelector();

  const formattedColumns = useMemo(() => {
    let tableColumns = _.cloneDeep(columns);
    //condition column numbering on table
    if (withNumbering && !isMobileView && !isTabView) {
      const findNumber = columns.find((e) => e?.key === "sequence-number");
      const fixedColumn = columns.find((e) => !!e?.fixed);
      if (_.isEmpty(findNumber)) {
        const numberColumn = {
          title: "No.",
          dataIndex: "number",
          key: "sequence-number",
          sorter: false,
          width: 75,
          render: (e, row, index) => {
            const number =
              data && data?.from ? parseInt(data?.from) + index : index + 1;
            return (
              <div>
                <span id="sequence-number">{number}</span>
              </div>
            );
          },
        };
        if (!_.isEmpty(fixedColumn)) {
          numberColumn.fixed = fixedColumn.fixed;
        }
        tableColumns.unshift(numberColumn);
      }
    }

    tableColumns = tableColumns.map((column) => {
      return {
        ...column,
        dataIndex:
          typeof column.dataIndex === "string"
            ? column.dataIndex.split(".")
            : column.dataIndex,
        responsive: isMobileView || isTabView ? [] : null,
        render: loading ? () => null : column.render,
      };
    });

    return tableColumns;
  }, [columns, data, isMobileView, isTabView, withNumbering, loading]);

  const renderMobileRow = (row) => {
    return formattedColumns.map((column, index) => {
      if (withTitle && index === 0) return null;

      let val = "";
      let colVal = "";

      if (column && column.dataIndex && column.dataIndex.length > 0) {
        colVal = row;
        for (let i = 0; i < column.dataIndex.length; i += 1) {
          if (
            column &&
            (colVal[column.dataIndex[i]] || colVal[column.dataIndex[i]] === 0)
          ) {
            colVal = colVal[column.dataIndex[i]];
          } else {
            colVal = null;
            break;
          }
        }
      }

      val =
        column.render && column.key !== "mobileView"
          ? column.render(colVal, row)
          : colVal;
      if (!val) return null;

      return (
        <Col
          xs={24}
          sm={12}
          key={column.key ? column.key : index}
          className="mt-3 px-1"
        >
          {column.title}
          {column.isCustomRender ? (
            <div className="mt-2">{val}</div>
          ) : (
            <span className="font-semibold text-[15px]">{val}</span>
          )}
        </Col>
      );
    });
  };

  if (isMobileView || isTabView) {
    formattedColumns.push({
      key: "mobileView",
      render: loading
        ? () => null
        : (text, row, idx) => {
            let val = "";

            if (
              withTitle &&
              formattedColumns[0] &&
              formattedColumns[0].dataIndex &&
              formattedColumns[0].dataIndex.length > 0
            ) {
              val = row;
              for (
                let i = 0;
                i < formattedColumns[0].dataIndex.length;
                i += 1
              ) {
                if (
                  formattedColumns[0] &&
                  val[formattedColumns[0].dataIndex[i]]
                ) {
                  val = val[formattedColumns[0].dataIndex[i]];
                } else {
                  val = null;
                  break;
                }
              }

              val =
                formattedColumns[0].render &&
                formattedColumns[0].key !== "mobileView"
                  ? formattedColumns[0].render(val, row, idx)
                  : val;
              if (val)
                val = (
                  <div
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                      padding: "4px 0",
                    }}
                  >
                    {val}
                  </div>
                );
            }

            return (
              <Card title={val} size="small">
                <Row>{renderMobileRow(row)}</Row>
              </Card>
            );
          },
    });
  }

  if (isFloatingHeader && !isTabView && !isMobileView) {
    props.sticky = { offsetHeader: 0 };
  }

  return (
    <ConfigProvider
      renderEmpty={() =>
        loading ? <div style={{ height: 140 }} /> : <Empty />
      }
      {...configProps}
    >
      <TableAntd
        style={{ minHeight: loading ? 200 : undefined, ...props?.style }}
        loading={
          loading && {
            indicator: (
              <div className="w-full">
                <Skeleton
                  paragraph={{ rows: 4 }}
                  className="ml-2 pr-2"
                  active
                />
              </div>
            ),
            style: { left: "-50%", top: "calc(-50% + 80px)" },
          }
        }
        columns={formattedColumns}
        rowSelection={
          rowSelection
            ? {
                ...rowSelection,
                renderCell: (checked, record, index, originNode) => (
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="w-full h-full absolute left-0 top-0 flex justify-center items-center cursor-default"
                  >
                    {originNode}
                  </div>
                ),
              }
            : undefined
        }
        rowClassName={typeof onRow === "function" ? "cursor-pointer" : ""}
        onRow={onRow}
        {...props}
      />
    </ConfigProvider>
  );
};

export default Table;

Table.defaultProps = {
  withTitle: true,
  withNumbering: true,
};
