import { useCallback, useMemo } from "react";
import { Dropdown } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";
import {
  PlusOutlined,
  ShopOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import store from "store";

import { useInitEmployee } from "hooks";
import { useHrCompanieSelector } from "hooks/useSelector";
import useSelectedCompany from "hooks/useSelectedCompany";
import { setConfig } from "redux/configSlice";

const CompanySelector = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isRoleEmployee } = useInitEmployee();
  const { companies, selectedCompany } = useHrCompanieSelector();
  const { onSelectCompany } = useSelectedCompany();

  const onChangeCompany = useCallback(
    (key) => {
      dispatch(setConfig({ role: null }));
      store.set("app.role", null);
      const findCompany = companies.find((row) => row.endpoint === key);
      if (findCompany) {
        onSelectCompany(findCompany);
      }
    },
    [companies, dispatch, onSelectCompany]
  );

  const changeCompany = useCallback(
    ({ key }) => {
      if (key === "list") {
        navigate("/companies");
      } else if (key === "add") {
        navigate("/companies/add");
      } else if (selectedCompany.endpoint !== key) {
        onChangeCompany(key);
      }
    },
    [navigate, onChangeCompany, selectedCompany]
  );

  const menuItems = useMemo(() => {
    const items = companies.map((item) => ({
      key: item.endpoint,
      label: item.name,
      icon: <ShopOutlined />,
    }));
    if (companies.length > 1) {
      items.push({
        key: "divider",
        type: "divider",
      });
      if (!isRoleEmployee) {
        items.push({
          key: "list",
          label: t("companies.company_list"),
          icon: <UnorderedListOutlined />,
        });
      }
    } else {
      if (!isRoleEmployee) {
        items.push({
          key: "add",
          label: t("companies.add_company"),
          icon: <PlusOutlined />,
        });
      }
    }
    return items;
    // return (
    //   <Menu
    //     selectedKeys={[selectedCompany?.endpoint]}
    //     onClick={changeCompany}
    //     items={items}
    //   />
    // );
  }, [companies, t, isRoleEmployee]);

  return (
    <Dropdown
      overlayClassName="pt-0"
      placement="bottomRight"
      menu={{
        items: menuItems,
        selectedKeys: [selectedCompany?.endpoint],
        onClick: changeCompany,
      }}
      trigger={["click"]}
    >
      <div className="flex flex-row items-center cursor-pointer">
        <div className="flex flex-wrap w-full">
          <span className="text-white font-medium uppercase leading-5">
            {selectedCompany?.name}
          </span>
        </div>
        <MdOutlineArrowDropDown className="text-white ml-1" />
      </div>
    </Dropdown>
  );
};

export default CompanySelector;
