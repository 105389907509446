import axios from "utils/axios";
import { stringify } from "query-string";

export const getTasksEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/tasks/employees/pagination?${query}`);
};

export const getTaskEss = (id) => {
  return axios.get(`/hr/tasks/employees/${id}`);
};

export const getTaskEmployeesEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/tasks/employees/employees?${query}`);
};

export const getTaskApproversEss = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/tasks/employees/approvers?${query}`);
};

export const addTaskEss = (data) => {
  return axios.post("/hr/tasks/employees", data);
};

export const editTaskEss = (data) => {
  return axios.put(`/hr/tasks/employees/${data?.id}`, data);
};

export const editStatusTaskEss = (data) => {
  return axios.patch(`/hr/tasks/employees/${data?.id}/status`, data);
};

export const archiveTaskEss = (id) => {
  return axios.patch(`/hr/tasks/employees/${id}/archive`);
};

export const unarchiveTaskEss = (id) => {
  return axios.patch(`/hr/tasks/employees/${id}/unarchive`);
};

export const approveTaskEss = (id) => {
  return axios.patch(`/hr/tasks/employees/${id}/approve`);
};

export const rejectTaskEss = (id) => {
  return axios.patch(`/hr/tasks/employees/${id}/reject`);
};

export const deleteTaskEss = (id) => {
  return axios.delete(`/hr/tasks/employees/${id}`);
};

export const deleteAttachmentTaskEss = (data) => {
  return axios.delete(
    `/hr/tasks/employees/${data?.taskId}/attachment/delete/${data?.id}`
  );
};
