import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
// import dayjsGenerateConfig from "rc-picker/lib/generate/dayjs";
// import generatePicker from "antd/es/date-picker/generatePicker";
// import "antd/es/date-picker/style/index";
import { DatePicker as DatePickerAntd } from "antd";
import useFormatting from "hooks/useFormatting";
import { useSelector } from "react-redux";
import { localeSelector } from "redux/selectors";

import id from "antd/es/date-picker/locale/id_ID";
import en from "antd/es/date-picker/locale/en_US";

// const DayjsDatePicker = generatePicker(dayjsGenerateConfig);

const formattedID = {
  ...id,
  lang: {
    ...id?.lang,
    shortWeekDays: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
    shortMonths: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "Mei",
      "Jun",
      "Jul",
      "Agu",
      "Sep",
      "Okt",
      "Nov",
      "Des",
    ],
  },
};

const DatePicker = ({ innerRef, ...props }) => {
  const { t } = useTranslation();
  const { formatDateText } = useFormatting();
  const localeConfig = useSelector(localeSelector);
  const locale = localeConfig === "en-US" ? en : formattedID;

  return (
    <DatePickerAntd
      ref={innerRef}
      format={formatDateText()}
      locale={{ ...locale, lang: { ...locale.lang, ok: t("button.save") } }}
      className="min-w-[200px]"
      {...props}
    />
  );
};

export default forwardRef((props, ref) => (
  <DatePicker innerRef={ref} {...props} />
));
