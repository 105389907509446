import { useMemo, useCallback, useState } from "react";
import { Dropdown, Select } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaUserEdit, FaSignOutAlt, FaUser, FaLink } from "react-icons/fa";
import { MdOutlineArrowDropDown } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import store from "store";
import dayjs from "dayjs";
import _ from "lodash";

import { useLogout } from "queries/user";
import {
  useAppSelector,
  useIsSettingExist,
  usePermissionsSetting,
  useSettingSelector,
} from "hooks/useSelector";
import { Upgrade } from "components/UI";
import { useInitEmployee } from "hooks";
import useFormatting from "hooks/useFormatting";
import useUrlKledo from "hooks/useUrlKledo";
import Avatar from "./Avatar";
import BillingConfirmDemoModal from "components/apps/Billing/Content/BillingConfirmDemo";
import { setConfig, setMenu, setPermissions } from "redux/configSlice";

const ProfileMenu = () => {
  const mutationLogout = useLogout();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatDate } = useFormatting();
  const { user, billing, option } = useAppSelector();
  const { isRoleEmployee, role } = useInitEmployee();
  const setting = useSettingSelector();
  const urlBillingKledo = useUrlKledo("#/settings/billing");
  const permissions = usePermissionsSetting("setting_billing");
  const hasSettingProfile = useIsSettingExist("profile");

  const [visibleDemo, setVisibleDemo] = useState(false);

  const logoutHandler = useCallback(() => {
    try {
      mutationLogout.mutate(null);
    } catch (error) {}
  }, [mutationLogout]);

  const goToUpgradeHandler = useCallback(() => {
    if (setting && !!setting.app_demo) {
      setVisibleDemo(true);
      return;
    }
    if (billing.can_be_renewed) {
      navigate("/settings/billing/upgrade?isRenew=1");
      return;
    }
    navigate("/settings/billing/upgrade");
  }, [navigate, billing, setting]);

  const selectRoleHandler = useCallback(
    (value) => {
      const isEmployee = value === "employee";
      const roleMenus = isEmployee ? user?.menus_employee : user?.menus;
      const rolePermissions = isEmployee
        ? user?.permissions_employee
        : user?.permissions;
      const firstUrlMenu = _.first(roleMenus?.dashboard);
      const mainUrlMenu = !_.isEmpty(firstUrlMenu?.sub)
        ? _.first(firstUrlMenu?.sub)?.url
        : firstUrlMenu?.url;

      const dashboardUrl = mainUrlMenu || "/dashboard";

      dispatch(setConfig({ role: value }));
      store.set("app.role", value);
      dispatch(setMenu(roleMenus));
      dispatch(setPermissions(rolePermissions));
      navigate(dashboardUrl);
    },
    [dispatch, user, navigate]
  );

  const hideConfirmDemo = useCallback(() => {
    setVisibleDemo(false);
  }, []);

  const menuItems = useMemo(() => {
    const items = [
      {
        key: "topBar.profileMenu.hello",
        label: <strong>Hello, {user?.name}</strong>,
        icon: <FaUser />,
      },
    ];

    if (user?.app_roles) {
      items.push(
        {
          key: "divider-0",
          type: "divider",
        },
        {
          key: "topBar.app_roles",
          label: (
            <>
              {user?.app_roles.length === 1 ? (
                <div>
                  <strong>{t("users.roles")}: </strong>
                  <span>{t(`roles.${role}`)}</span>
                </div>
              ) : (
                <div
                  className="flex flex-col items-start w-full gap-y-1"
                  onClick={(e) => e?.stopPropagation()}
                >
                  <strong>{t("users.roles")}: </strong>
                  <Select
                    className="w-full"
                    defaultValue={role}
                    options={user?.app_roles.map((row) => ({
                      label: t(`roles.${row}`),
                      value: row,
                    }))}
                    onSelect={selectRoleHandler}
                  />
                </div>
              )}
            </>
          ),
        }
      );
    }

    if (billing && !!permissions?.isCanAdd) {
      items.push(
        {
          key: "divider-1",
          type: "divider",
        },
        {
          key: "topBar.billing.plan",
          label: (
            <>
              <strong>{t("topBar.billing.plan")}: </strong>
              {billing && billing.app_plan && billing.app_plan.name
                ? billing.app_plan.name
                : "-"}
              <br />
              <strong>{t("topBar.billing.active_until")}: </strong>
              {billing && billing.expiry_date
                ? formatDate(dayjs(billing.expiry_date))
                : t("topBar.billing.forever")}
              <br />
              {(billing.can_be_upgraded || billing.can_be_renewed) && (
                <>
                  {option?.bundling_finance_hr === 1 ? (
                    <Upgrade
                      href={urlBillingKledo}
                      target="_blank"
                      rel="noopener noreferrer"
                      type="primary"
                      title={t(
                        billing.can_be_renewed
                          ? "button.renew"
                          : "button.upgrade"
                      )}
                      className="w-fit text-white"
                    />
                  ) : (
                    <Upgrade
                      onClick={goToUpgradeHandler}
                      title={t(
                        billing.can_be_renewed
                          ? "button.renew"
                          : "button.upgrade"
                      )}
                    />
                  )}
                </>
              )}
            </>
          ),
        }
      );
    }

    items.push(
      {
        key: "divider-2",
        type: "divider",
      },
      {
        key: "topBar.profileMenu.email",
        label: (
          <div>
            <strong>{t("topBar.profileMenu.email")}: </strong>
            {user?.email}
            <br />
            <strong>{t("topBar.profileMenu.phone")}: </strong>
            {user?.phone_number}
          </div>
        ),
      }
    );

    if (!isRoleEmployee) {
      items.push(
        {
          key: "divider-3",
          type: "divider",
        },
        {
          key: "referral",
          label: <Link to="/referral">{t("referral.referral")}</Link>,
          icon: <FaLink size={13} />,
        }
      );

      //check condition if has setting profile
      if (hasSettingProfile) {
        items.push(
          {
            key: "divider-4",
            type: "divider",
          },
          {
            key: "topBar.profileMenu.editProfile",
            label: (
              <Link to="/settings/profile">
                {t("topBar.profileMenu.editProfile")}
              </Link>
            ),
            icon: <FaUserEdit size={15} />,
          }
        );
      }
    }

    items.push(
      {
        key: "divider-5",
        type: "divider",
      },
      {
        key: "topBar.profileMenu.logout",
        label: t("topBar.profileMenu.logout"),
        icon: <FaSignOutAlt />,
        onClick: logoutHandler,
      }
    );

    return items;
  }, [
    logoutHandler,
    user?.email,
    user?.name,
    user?.phone_number,
    user?.app_roles,
    t,
    billing,
    formatDate,
    goToUpgradeHandler,
    option,
    urlBillingKledo,
    isRoleEmployee,
    role,
    hasSettingProfile,
    permissions,
    selectRoleHandler,
  ]);

  return (
    <>
      <Dropdown
        overlayClassName="pt-4"
        placement="bottomRight"
        menu={{ items: menuItems, selectable: false }}
        trigger={["click"]}
      >
        <div className="flex flex-row items-center gap-1 cursor-pointer">
          <div className="bg-white dark:bg-dark1 w-8 h-8 rounded-full p-1 relative flex items-center justify-center">
            <Avatar imageUrl={user?.profile_image} />
          </div>
          <MdOutlineArrowDropDown className="text-white" />
        </div>
      </Dropdown>
      <BillingConfirmDemoModal
        visible={visibleDemo}
        onCancel={hideConfirmDemo}
      />
    </>
  );
};

export default ProfileMenu;
