import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import _ from "lodash";

import * as api from "apis/task";
import { useReferenceSelector } from "hooks/useSelector";

export function useTasks(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["tasks", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTasks(payload);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useTasksPagination = (payload = {}, options = {}) => {
  // const { t } = useTranslation();
  return useInfiniteQuery(
    ["tasks/pagination", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTasks({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error("Failed to load data from server!");
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) => {
        // message.error(t("error.failed_to_load_data_from_server"));
      },
      ...options,
    }
  );
};

export function useTask(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["task", payload],
    async () => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTask(payload?.id);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export function useAttachmentTask(payload = {}, options = {}) {
  const { t } = useTranslation();
  return useQuery(
    ["task/attachment", payload],
    async () => {
      let response;
      try {
        const { data } = await api.getAttachmentTask(payload?.url);
        response = data;
      } catch (error) {}
      return response;
    },
    {
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
}

export const useTaskEmployeesSuggestion = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["task/employees/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskEmployees({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useTaskApproversSuggestion = (payload = {}, options = {}) => {
  const { t } = useTranslation();
  return useInfiniteQuery(
    ["task/approvers/suggestion", payload],
    async ({ pageParam = 1 }) => {
      let response;
      try {
        const {
          data: { data },
        } = await api.getTaskApprovers({ page: pageParam, ...payload });
        response = data;
      } catch (error) {
        throw new Error(t("error.failed_to_load_data_from_server"));
      }
      return response;
    },
    {
      getNextPageParam: (lastPage) => {
        if (!lastPage) {
          return undefined;
        }
        return lastPage.current_page < lastPage.last_page
          ? lastPage.current_page + 1
          : undefined;
      },
      onError: (error) =>
        message.error(t("error.failed_to_load_data_from_server")),
      ...options,
    }
  );
};

export const useAddTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useAddDuplicateTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.addDuplicateTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useEditTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.editTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUpdateStatusTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editStatusTask(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries(["task", { id: payload?.id }]);
      const prev = queryClient.getQueryData(["task", { id: payload?.id }]);
      queryClient.setQueryData(["task", { id: payload?.id }], (old) => {
        if (old) {
          old.hr_task_status = payload.hr_task_status;
          if (payload?.hr_task_status_id === 3) {
            old.is_approvable = old?.hr_task_approval_status?.id !== 2 ? 1 : 0;
            old.is_rejectable = old?.hr_task_approval_status?.id !== 3 ? 1 : 0;
          } else {
            old.is_approvable = 0;
            old.is_rejectable = 0;
          }
          return old;
        }
      });
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(["task", { id: payload?.id }], context.prev);
      message.error(t("error.failed_save_data_to_server"));
    },
    onSettled: (response, error, payload) => {
      queryClient.invalidateQueries(["task", { id: payload?.id }]);
    },
  });
};

export const useEditStatusTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(
    (payload) =>
      api.editStatusTask({
        id: payload?.id,
        hr_task_status_id: payload?.hr_task_status_id,
      }),
    {
      onMutate: async (payload) => {
        await queryClient.cancelQueries([
          "tasks/pagination",
          payload.source.queryKey,
        ]);
        await queryClient.cancelQueries([
          "tasks/pagination",
          payload.destination.queryKey,
        ]);

        const prevSource = queryClient.getQueryData([
          "tasks/pagination",
          payload.source.queryKey,
        ]);
        const prevDestination = queryClient.getQueryData([
          "tasks/pagination",
          payload.destination.queryKey,
        ]);

        queryClient.setQueryData(
          ["tasks/pagination", payload.source.queryKey],
          (old) => {
            if (old) {
              const pages = _.cloneDeep(old?.pages);
              const newPages = pages.map((page) => {
                const findData = page.data.filter(
                  (row) => row?.id !== payload?.id
                );
                page.total -= 1;
                return { ...page, data: findData };
              });
              old.pages = newPages;
              return old;
            }
          }
        );

        queryClient.setQueryData(
          ["tasks/pagination", payload.destination.queryKey],
          (old) => {
            if (old) {
              const pages = _.cloneDeep(old?.pages);
              const newPages = pages.map((page, index) => {
                if (index === 0) {
                  page.total += 1;
                  if (!_.isEmpty(page?.data)) {
                    page.data = [{ ...payload.source.data }, ...page.data];
                  } else {
                    page.data = [{ ...payload.source.data }];
                  }
                }
                return page;
              });
              old.pages = newPages;
              return old;
            }
          }
        );

        return { prevSource, prevDestination };
      },
      onSuccess: ({ data }) => {
        if (data?.success) {
          message.success(data?.message);
        } else {
          message.error(data?.message);
        }
      },
      onError: (error, payload, context) => {
        queryClient.setQueryData(
          ["tasks/pagination", payload.source.queryKey],
          context.prevSource
        );
        queryClient.setQueryData(
          ["tasks/pagination", payload.destination.queryKey],
          context.prevDestination
        );
        message.error(t("error.failed_save_data_to_server"));
      },
      onSettled: (data, error, payload) => {
        queryClient.invalidateQueries([
          "tasks/pagination",
          payload.source.queryKey,
        ]);
        queryClient.invalidateQueries([
          "tasks/pagination",
          payload.destination.queryKey,
        ]);
      },
    }
  );
};

export const useDeleteTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useMassDeleteTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.massDeleteTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useDeleteAttachmentTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.deleteAttachmentTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useArchiveTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.archiveTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useUnarchiveTask = () => {
  const { t } = useTranslation();
  return useMutation((payload) => api.unarchiveTask(payload), {
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error) => message.error(t("error.failed_save_data_to_server")),
  });
};

export const useApproveTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { taskApprovalStatuses } = useReferenceSelector();
  return useMutation((payload) => api.approveTask(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries(["task", { id: payload }]);
      const prev = queryClient.getQueryData(["task", { id: payload }]);
      queryClient.setQueryData(["task", { id: payload }], (old) => {
        const approvalStatus = taskApprovalStatuses.find(
          (row) => row?.id === 2
        );
        if (old) {
          old.hr_task_approval_status = approvalStatus;
          old.is_approvable = 0;
          old.is_rejectable = 1;
          return old;
        }
      });
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(["task", { id: payload }], context.prev);
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

export const useRejectTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { taskApprovalStatuses } = useReferenceSelector();
  return useMutation((payload) => api.rejectTask(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries(["task", { id: payload }]);
      const prev = queryClient.getQueryData(["task", { id: payload }]);
      queryClient.setQueryData(["task", { id: payload }], (old) => {
        const approvalStatus = taskApprovalStatuses.find(
          (row) => row?.id === 3
        );
        if (old) {
          old.hr_task_approval_status = approvalStatus;
          old.is_approvable = 1;
          old.is_rejectable = 0;
          return old;
        }
      });
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(["task", { id: payload }], context.prev);
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};

export const useUpdateApproverTask = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  return useMutation((payload) => api.editApproverTask(payload), {
    onMutate: async (payload) => {
      await queryClient.cancelQueries(["task", { id: payload?.id }]);
      const prev = queryClient.getQueryData(["task", { id: payload?.id }]);
      queryClient.setQueryData(["task", { id: payload?.id }], (old) => {
        if (old) {
          const prevData = _.cloneDeep(old);
          prevData.approver = {
            ...payload?.approver,
            profile_image: null,
          };
          return prevData;
        }
      });
      return { prev };
    },
    onSuccess: ({ data }) => {
      if (data?.success) {
        message.success(data?.message);
      } else {
        message.error(data?.message);
      }
    },
    onError: (error, payload, context) => {
      queryClient.setQueryData(["task", { id: payload?.id }], context.prev);
      message.error(t("error.failed_save_data_to_server"));
    },
  });
};
