import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, Form, Input, Rate, message } from "antd";

import { useAppSelector } from "hooks/useSelector";
import { css } from "@emotion/css";
import { useCreateNps, useIgnoreNps } from "queries";
import {
  CloseOutlined,
  LoadingOutlined,
  SendOutlined,
} from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { setApp } from "redux/configSlice";

const Character = ({ index, value }) => {
  return (
    <div
      className={`w-[26px] h-[26px] flex justify-center items-center text-base text-center rounded-[50%] ${
        value >= index + 1
          ? "bg-primary text-white"
          : "bg-[rgb(228,233,240)] text-gray-900"
      }`}
    >
      <span className="text-[13px]">{index}</span>
    </div>
  );
};

const NPS = () => {
  const formRef = useRef();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const app = useAppSelector();
  const showNps = app?.showNps;
  const createNps = useCreateNps();
  const ignoreNps = useIgnoreNps();
  const { isLoading: loadingNps } = createNps;
  const { isLoading: loadingIgnore } = ignoreNps;

  const [sent, setSent] = useState(false);

  const sendNpsHandler = useCallback(
    (values) => {
      createNps.mutate(
        { ...values, score: values?.score ? values?.score - 1 : 0 },
        {
          onSuccess: (res) => {
            if (res.data.success) {
              setSent(true);
            }
          },
          onError: () => {
            setSent(false);
            message.error(t("nps.cannot_send_nps"));
          },
        }
      );
    },
    [t, createNps]
  );

  const ignoreNpsHandler = useCallback(() => {
    if (sent) {
      setSent(false);
      dispatch(setApp({ showNps: 0 }));
      return;
    }
    if (loadingIgnore) return;
    ignoreNps.mutate();
  }, [ignoreNps, sent, loadingIgnore, dispatch]);

  if (!showNps && !sent) {
    return null;
  }

  return (
    <Card
      className={`fixed z-[12] w-[90vw] max-w-[420px] transition-all duration-[ease] delay-[0.5s] left-4 bottom-4 ${css`
        .ant-card-head-title {
          flex: 1 1 !important;
          padding: 16px 0 !important;
          white-space: normal !important;
        }
      `}`}
      title={t(
        sent ? "nps.thank_you_your_input" : "nps.how_likely_you_recommend"
      )}
      styles={{
        header: {
          backgroundColor: "var(--primary-color)",
          color: "white",
          fontWeight: "bold",
        },
        body: sent
          ? {
              padding: 0,
            }
          : {},
      }}
    >
      <Button
        type="primary"
        shape="circle"
        className="absolute right-2 top-2"
        icon={loadingIgnore ? <LoadingOutlined /> : <CloseOutlined />}
        onClick={ignoreNpsHandler}
      />
      {!sent && (
        <>
          <Form
            ref={formRef}
            layout="vertical"
            name="nps_form"
            initialValues={{
              score: 0,
              message: "",
            }}
            onFinish={sendNpsHandler}
          >
            <Form.Item name="score">
              <Rate count={11} character={Character} />
            </Form.Item>
            <div className="flex flex-row justify-between mt-2 mb-5">
              <span className="text-[13px]">
                <b>0</b>
                {` - ${t("nps.not_likely")}`}
              </span>
              <span className="text-[13px]">
                <b>10</b>
                {` - ${t("nps.very_likely")}`}
              </span>
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.score !== next.score}
            >
              {({ getFieldValue }) => (
                <>
                  {!!getFieldValue("score") && (
                    <>
                      <Form.Item
                        name="message"
                        label={t("nps.please_mention_another")}
                      >
                        <Input.TextArea autoSize={{ minRows: 3, maxRows: 3 }} />
                      </Form.Item>
                      <Form.Item noStyle>
                        <Button
                          htmlType="submit"
                          type="primary"
                          icon={<SendOutlined />}
                          className="float-right"
                          loading={loadingNps}
                        >
                          {t("button.send")}
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </>
              )}
            </Form.Item>
          </Form>
        </>
      )}
    </Card>
  );
};

export default NPS;
