import { useMemo } from "react";
import _ from "lodash";

import { useRoleSelector, useUserSelector } from "./useSelector";

const useInitEmployee = () => {
  const user = useUserSelector();
  const role = useRoleSelector();

  const isRoleEmployee = useMemo(() => {
    return role === "employee";
  }, [role]);

  const isRoleAdmin = useMemo(() => {
    return role === "admin";
  }, [role]);

  const isEmployeeLeader = useMemo(() => {
    return !!isRoleEmployee && !!user?.is_leader;
  }, [isRoleEmployee, user?.is_leader]);

  const employee = useMemo(() => {
    if (!_.isEmpty(user?.employee)) {
      return user.employee;
    }
    return {};
  }, [user]);

  return { isRoleEmployee, isRoleAdmin, isEmployeeLeader, role, employee };
};

export default useInitEmployee;
