import axios from "utils/axios";
import { stringify } from "query-string";

export const getSettingSpecialLeavesPagination = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/specialLeaves/pagination?${query}`);
};

export const getSettingSpecialLeaves = (params) => {
  const query = stringify(params);
  return axios.get(`/hr/specialLeaves?${query}`);
};

export const getSettingSpecialLeave = (id) => {
  return axios.get(`/hr/specialLeaves/${id}`);
};

export const getSettingSpecialLeaveCanBeDeleted = () => {
  return axios.get(`/hr/specialLeaves/canBeDeleted`);
};

export const addSettingSpecialLeave = (data) => {
  return axios.post("/hr/specialLeaves", data);
};

export const editSettingSpecialLeave = (data) => {
  return axios.put(`/hr/specialLeaves/${data.id}`, data);
};

export const unarchiveSettingSpecialLeave = (id) => {
  return axios.patch(`/hr/specialLeaves/${id}/activate`);
};

export const archiveSettingSpecialLeave = (id) => {
  return axios.patch(`/hr/specialLeaves/${id}/inactivate`);
};

export const deleteSettingSpecialLeave = (id) => {
  return axios.delete(`/hr/specialLeaves/${id}`);
};
