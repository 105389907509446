import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Menu, Skeleton, Space } from "antd";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch } from "react-redux";
import { css } from "@emotion/css";
import classname from "classname";
import store from "store";
import _ from "lodash";

import Logo from "assets/images/logo.png";
import FavIcon from "assets/images/favicon.png";
import {
  useAppSelector,
  useConfigSelector,
  useSettingSelector,
} from "hooks/useSelector";
import { setConfig } from "redux/configSlice";
import IconMenuItem from "./IconMenuItem";
import { DemoWarning } from "components/UI";
import useUrlKledo from "hooks/useUrlKledo";
import useURLDefaultParams from "hooks/useURLDefaultParams";

const Sider = () => {
  const location = useLocation();
  const scrollBarRef = useRef();
  const dispatch = useDispatch();
  const selectedKeysFromStore = store.get("app.menu.selectedKeys");
  const openedKeysFromStore = store.get("app.menu.openedKeys");
  const [selectedKeys, setSelectedKeys] = useState(selectedKeysFromStore || []);
  const [openedKeys, setOpenedKeys] = useState(openedKeysFromStore || []);
  const { menu, loadingInit, option } = useAppSelector();
  const { isMobileView, isMenuCollapsed } = useConfigSelector();
  const { addURLDefaultParams } = useURLDefaultParams();
  const settings = useSettingSelector();
  const urlKledo = useUrlKledo();
  const pathName = location.pathname;

  const flattenItems = useCallback(
    (items, key) =>
      items.reduce((flattenedItems, item) => {
        flattenedItems.push(item);
        if (Array.isArray(item[key])) {
          return flattenedItems.concat(flattenItems(item[key], key));
        }
        return flattenedItems;
      }, []),
    []
  );

  const makeSelectedKeys = useCallback(() => {
    const flattenData = flattenItems(menu, "sub");
    let findMenuItem = null;
    const activePath = pathName?.split("/");
    if (pathName === "/") {
      findMenuItem = _.find(flattenData, { url: pathName });
    } else {
      flattenData.forEach((item) => {
        if (
          item.url !== "/" &&
          item.url !== "" &&
          pathName.includes(item.url) &&
          activePath &&
          activePath.length > 1 &&
          (activePath[1] === item.name ||
            activePath[2] === item.name.replace("_", "-"))
        ) {
          findMenuItem = item;
        }
      });
    }
    // const findMenuItem = menu.find((item) => item.url.includes(pathName));
    if (findMenuItem) {
      store.set("app.menu.selectedKeys", [findMenuItem.key]);
      setSelectedKeys([findMenuItem.key]);
    }
  }, [flattenItems, menu, pathName]);

  const toggleMenuColllapsedHandler = useCallback(
    (collapsed) => {
      store.set("app.menu.isMenuCollapsed", collapsed);
      dispatch(setConfig({ isMenuCollapsed: collapsed }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (pathName) {
      makeSelectedKeys();
    }
  }, [makeSelectedKeys, pathName]);

  const menuSettings = useMemo(() => {
    return isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          collapsedWidth: 0,
          onCollapse: toggleMenuColllapsedHandler,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: toggleMenuColllapsedHandler,
          breakpoint: "lg",
        };
  }, [isMenuCollapsed, isMobileView, toggleMenuColllapsedHandler]);

  const renderMenuItems = useMemo(() => {
    const generateItem = (item) => {
      const id = `app-menu-${item.name}`;
      if (item.divider) {
        return {
          type: "divider",
          key: Math.random(),
        };
      }
      if (item.url) {
        const url = addURLDefaultParams(item.url);
        let linkComponent = <Link to={url}>{item.title}</Link>;
        if (item.name === "accounting" && option?.app_connect_finance) {
          linkComponent = (
            <a target="_blank" href={urlKledo} rel="noopener noreferrer">
              {item.title}
            </a>
          );
        }
        return {
          icon: (
            <IconMenuItem
              className="text-gray-800 dark:text-white"
              name={item.name}
            />
          ),
          key: item.key,
          id: id,
          label: linkComponent,
          disabled: item.disabled,
        };
      }
      return {
        icon: (
          <IconMenuItem
            className="text-gray-800 dark:text-white"
            name={item.name}
          />
        ),
        key: item.key,
        id: id,
        label: item.title,
        disabled: item.disabled,
      };
    };

    const generateSubmenu = (items) => {
      return items.map((item) => {
        const id = `app-menu-${item.name}`;

        if (item.sub) {
          return {
            icon: (
              <IconMenuItem
                className="text-gray-800 dark:text-white"
                name={item.name}
              />
            ),
            key: item.key,
            id: id,
            label: item.title,
            chidlren: generateSubmenu(item.sub),
          };
        }
        return generateItem(item);
      });
    };

    return menu.map((item) => {
      const id = `app-menu-${item.name}`;
      if (item.sub) {
        const subMenuTitle = (
          <span key={item.key}>
            {!_.isEmpty(item.url) ? (
              <Link
                className={
                  item.sub && item.sub.length > 0 ? "pointer-events-none" : ""
                }
                to={item.url}
              >
                {item.title}
              </Link>
            ) : (
              <span>{item.title}</span>
            )}
          </span>
        );
        return {
          icon: (
            <IconMenuItem
              className="text-gray-800 dark:text-white"
              name={item.name}
            />
          ),
          key: item.key,
          id: id,
          label: subMenuTitle,
          children: generateSubmenu(item.sub),
        };
      }

      return generateItem(item);
    });
  }, [menu, option?.app_connect_finance, urlKledo, addURLDefaultParams]);

  const clickMenuHandler = useCallback((e) => {
    // const { isSettingsOpen, onChangeSetting } = this.props
    store.set("app.menu.selectedKeys", [e.key]);
    if (e.keyPath[1]) {
      store.set("app.menu.openedKeys", [e.keyPath[1]]);
    } else {
      store.set("app.menu.openedKeys", []);
    }

    setSelectedKeys([e.key]);
    setOpenedKeys(e.keyPath[1] ? [e.keyPath[1]] : []);
  }, []);

  const openChangeHandler = useCallback((openedKeys) => {
    setOpenedKeys(openedKeys);
  }, []);

  const makeLoader = useMemo(() => {
    const loaders = [];
    for (let i = 1; i <= 10; i += 1) {
      loaders.push(
        <Space key={i}>
          <Skeleton.Avatar active size="small" shape="circle" />
          {!isMenuCollapsed && (
            <Skeleton.Input className="w-[180px]" active size="small" />
          )}
        </Space>
      );
    }
    return loaders;
  }, [isMenuCollapsed]);

  return (
    <Layout.Sider
      className={`bg-white dark:bg-dark1 relative transition-all duration-300 shadow-2xl ${css`
        .ant-layout-sider-trigger {
          background-color: ${settings?.dark_mode
            ? "#3f1a25"
            : "#e5e7eb"}!important;
        }
      `}`}
      theme={settings?.dark_mode ? "dark" : "light"}
      {...menuSettings}
    >
      <div
        className={classname("flex h-[90px] justify-center items-center", {
          "my-5": !!option?.property_logo && !isMenuCollapsed,
        })}
      >
        <Link to="/">
          {isMenuCollapsed ? (
            <img width={50} src={FavIcon} alt="Logo" />
          ) : (
            <img
              width={110}
              src={option?.property_logo || Logo}
              className="object-contain"
              alt="Logo"
            />
          )}
        </Link>
      </div>
      {(!isMenuCollapsed || isMobileView) && (
        <DemoWarning key="demo" showIcon={!isMenuCollapsed || isMobileView} />
      )}
      <Scrollbars
        ref={scrollBarRef}
        className="flex flex-1"
        renderThumbVertical={({ style, ...props }) => (
          <div
            {...props}
            style={{
              ...style,
              width: "4px",
              borderRadius: "inherit",
              backgroundColor: "#c5cdd2",
              left: "1px",
            }}
          />
        )}
        autoHide
      >
        {loadingInit ? (
          <Space className="mt-5 ml-4" direction="vertical" size={30}>
            {makeLoader}
          </Space>
        ) : (
          <Menu
            mode="inline"
            selectedKeys={selectedKeys}
            onClick={clickMenuHandler}
            openKeys={openedKeys}
            onOpenChange={openChangeHandler}
            items={renderMenuItems}
            className="border-r-0"
          />
        )}
      </Scrollbars>
      {!isMobileView && <div style={{ height: 46 }} />}
    </Layout.Sider>
  );
};

export default Sider;
