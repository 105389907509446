import axios from "utils/axios";

export const getThr = () => {
  return axios.get("/hr/settings/thrs");
};

export const editThr = (data) => {
  return axios.patch("/hr/settings/thrs", data);
};

export const getLeaveAllowance = () => {
  return axios.get("/hr/settings/leaveAllowances");
};

export const editLeaveAllowance = (data) => {
  return axios.patch("/hr/settings/leaveAllowances", data);
};

export const getOvertimePay = () => {
  return axios.get("/hr/settings/overtimePays");
};

export const editOvertimePay = (data) => {
  return axios.patch("/hr/settings/overtimePays", data);
};

export const getProRate = () => {
  return axios.get("/hr/settings/prorates");
};

export const editProRate = (data) => {
  return axios.patch("/hr/settings/prorates", data);
};

export const getSettingAnnualLeave = () => {
  return axios.get("/hr/settings/annualLeaves");
};

export const editSettingAnnualLeave = (data) => {
  return axios.patch("/hr/settings/annualLeaves", data);
};

export const getSettingSickLeave = () => {
  return axios.get("/hr/settings/sickLeaves");
};

export const editSettingSickLeave = (data) => {
  return axios.patch("/hr/settings/sickLeaves", data);
};

export const getPph21s = () => {
  return axios.get("/hr/settings/pph21s");
};

export const editPph21s = (data) => {
  return axios.patch("/hr/settings/pph21s", data);
};

export const getBpjsSocialSecurities = () => {
  return axios.get("/hr/settings/bpjsSocialSecurities");
};

export const editBpjsSocialSecurities = (data) => {
  return axios.patch("/hr/settings/bpjsSocialSecurities", data);
};

export const getBpjsHealthcares = () => {
  return axios.get("/hr/settings/bpjsHealthcares");
};

export const editBpjsHealthcares = (data) => {
  return axios.patch("/hr/settings/bpjsHealthcares", data);
};

export const getUnpaidLeaveDeductions = () => {
  return axios.get("/hr/settings/unpaidLeaveDeductions");
};

export const editUnpaidLeaveDeductions = (data) => {
  return axios.patch("/hr/settings/unpaidLeaveDeductions", data);
};

export const getSettingApps = () => {
  return axios.get("/hr/settings/apps");
};

export const getMobileMenus = () => {
  return axios.get("/hr/settings/mobileMenus");
};

export const editMobileMenus = (data) => {
  return axios.patch("/hr/settings/mobileMenus", data);
};

export const getSettingAttendanceLogRule = () => {
  return axios.get("/hr/settings/attendanceLogRule");
};

export const editSettingAttendanceLogRule = (data) => {
  return axios.patch("/hr/settings/attendanceLogRule", data);
};

export const getSettingsThemeColors = () => {
  return axios.get("/hr/settings/themeColors");
};

export const editSettingThemeColors = (data) => {
  return axios.patch("/hr/settings/themeColors", data);
};

export const getSettings = () => {
  return axios.get("/hr/settings");
};

export const editSettingApps = (data) => {
  return axios.patch("/hr/settings/apps", data);
};

export const getSettingsTimeline = () => {
  return axios.get("/hr/settings/timelines");
};

export const editSettingTimeline = (data) => {
  return axios.patch("/hr/settings/timelines", data);
};

export const getSettingsBpjsBasics = () => {
  return axios.get("/hr/settings/bpjsBasics");
};

export const editSettingBpjsBasics = (data) => {
  return axios.patch("/hr/settings/bpjsBasics", data);
};

export const getSettingsCashReceipts = () => {
  return axios.get("/hr/settings/cashReceipts");
};

export const editSettingCashReceipts = (data) => {
  return axios.patch("/hr/settings/cashReceipts", data);
};

export const getSettingsPaginations = () => {
  return axios.get("/hr/settings/paginations");
};

export const editSettingPaginations = (data) => {
  return axios.patch("/hr/settings/paginations", data);
};

export const getSettingsReminders = () => {
  return axios.get("/hr/settings/reminders");
};

export const editSettingReminders = (data) => {
  return axios.patch("/hr/settings/reminders", data);
};

export const getSettingsShowColumns = () => {
  return axios.get("/hr/settings/showColumns");
};

export const editSettingShowColumns = (data) => {
  return axios.patch("/hr/settings/showColumns", data);
};

export const getSettingsFilters = () => {
  return axios.get("/hr/settings/filters");
};

export const editSettingsFilters = (data) => {
  return axios.patch("/hr/settings/filters", data);
};

export const getSettingsCompanyNotificationEss = () => {
  return axios.get("/hr/settings/companySettingNotificationEss");
};

export const editSettingCompanyNotificationEss = (data) => {
  return axios.patch("/hr/settings/companySettingNotificationEss", data);
};

export const getSettingsCompanyPostEss = () => {
  return axios.get("/hr/settings/companySettingPostEss");
};

export const editSettingCompanyPostEss = (data) => {
  return axios.patch("/hr/settings/companySettingPostEss", data);
};

export const getSettingsLocationTrackingRule = () => {
  return axios.get("/hr/settings/locationTrackingRule");
};

export const editSettingLocationTrackingRule = (data) => {
  return axios.patch("/hr/settings/locationTrackingRule", data);
};

export const getSettingsCompanyTeamEss = () => {
  return axios.get("/hr/settings/companySettingTeamEss");
};

export const editSettingCompanyTeamEss = (data) => {
  return axios.patch("/hr/settings/companySettingTeamEss", data);
};

export const getSettingsNumber = () => {
  return axios.get("/hr/settings/numbers");
};

export const editSettingNumber = (data) => {
  return axios.patch("/hr/settings/numbers", data);
};

export const getSettingsReimbursement = () => {
  return axios.get("/hr/settings/reimbursements");
};

export const editSettingReimbursment = (data) => {
  return axios.patch("/hr/settings/reimbursements", data);
};

export const getSettingsTask = () => {
  return axios.get("/hr/settings/tasks");
};

export const editSettingTask = (data) => {
  return axios.patch("/hr/settings/tasks", data);
};

export const getSettingsOvertimeExport = () => {
  return axios.get("/hr/settings/overtimeExports");
};

export const editSettingOvertimeExport = (data) => {
  return axios.patch("/hr/settings/overtimeExports", data);
};

export const getSettingsRounding = () => {
  return axios.get("/hr/settings/rounding");
};

export const editSettingRounding = (data) => {
  return axios.patch("/hr/settings/rounding", data);
};

export const getSettingsSeverances = () => {
  return axios.get("/hr/settings/severances");
};

export const editSettingSeverances = (data) => {
  return axios.patch("/hr/settings/severances", data);
};

export const getSettingsTaskDisplay = () => {
  return axios.get("/hr/settings/tasks/display");
};

export const editSettingsTaskDisplay = (data) => {
  return axios.patch("/hr/settings/tasks/display", data);
};

export const getSettingsTaskDisplayEss = () => {
  return axios.get("/hr/settings/employees/tasks/display");
};

export const editSettingsTaskDisplayEss = (data) => {
  return axios.patch("/hr/settings/employees/tasks/display", data);
};

export const uploadImportBpjsMultiplierPerEmployee = (data) => {
  return axios.post(
    `/hr/settings/bpjsMultiplierPerEmployee/uploadImport`,
    data,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const executeImportBpjsMultiplierPerEmployee = (data) => {
  return axios.post(
    `/hr/settings/bpjsMultiplierPerEmployee/executeImport`,
    data
  );
};
